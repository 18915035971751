import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import DashboardLayout from 'template/layouts/dashboard';

export const IndexPage = lazy(() => import('template/pages/app'));
export const BlogPage = lazy(() => import('template/pages/blog'));
export const UserPage = lazy(() => import('template/pages/user'));
export const LoginPage = lazy(() => import('template/pages/login'));
export const RegisterPage = lazy(() => import('template/pages/register'));
export const LogoutPage = lazy(() => import('template/pages/logout'));
export const ShopsPage = lazy(() => import('template/pages/shops'));
export const ShopCreatePage = lazy(() => import('template/pages/shop-create'));
export const ShopDetailPage = lazy(() => import('template/pages/shop-detail'));
export const ShopEditPage = lazy(() => import('template/pages/shop-edit'));


export const CategoryListPage = lazy(() => import('template/pages/category/category-list'));
export const CategoryCreatePage = lazy(() => import('template/pages/category-create'));
export const CategoryDetailPage = lazy(() => import('template/pages/category-detail'));
export const CategoryEditPage = lazy(() => import('template/pages/category-edit'));

export const MenuListPage = lazy(() => import('template/pages/menu/menu-list'));
export const MenuCreatePage = lazy(() => import('template/pages/menu-create'));
export const MenuDetailPage = lazy(() => import('template/pages/menu-detail'));
export const MenuEditPage = lazy(() => import('template/pages/menu-edit'));

export const ItemListPage = lazy(() => import('template/pages/item/item-list'));
export const ItemCreatePage = lazy(() => import('template/pages/item-create'));
export const ItemDetailPage = lazy(() => import('template/pages/item-detail'));







export const ProductsPage = lazy(() => import('template/pages/products'));
export const Page404 = lazy(() => import('template/pages/page-not-found'));

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      element: (
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      ),
      children: [
        { element: <IndexPage />, index: true },
        { path: 'users', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'shops', element: <ShopsPage />, },
        { path: 'shops/create', element: <ShopCreatePage />, },
        { path: 'shops/:id/detail', element: <ShopDetailPage />, },
        { path: 'shops/:id/edit', element: <ShopEditPage />, },

        { path: 'categories', element: <CategoryListPage />, },
        { path: 'categories/create', element: <CategoryCreatePage />, },
        { path: 'categories/:id/detail', element: <CategoryDetailPage />, },
        { path: 'categories/:id/edit', element: <CategoryEditPage />, },

        { path: 'menus', element: <MenuListPage />, },
        { path: 'menus/create', element: <MenuCreatePage />, },
        { path: 'menus/:id/detail', element: <MenuDetailPage />, },
        { path: 'menus/:id/edit', element: <MenuEditPage />, },

        { path: 'items', element: <ItemListPage />, },
        { path: 'items/create', element: <ItemCreatePage />, },
        { path: 'items/:id/detail', element: <ItemDetailPage />, },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: 'logout',
      element: <LogoutPage />,
    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
