import axios from 'axios';

import { ITEM_API_ENDPOINT } from '../../config/config';
import { WAITING_TIME } from '../../config/config';

// GET REQUEST
export function selectItemListApi({ access_token, page }) {
  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    const ENDPOINT = ITEM_API_ENDPOINT + '?page=' + page;
    axios
      .get(ENDPOINT, config)
      .then((response) => {
        setTimeout(() => resolve(response.data), 3000);
        //resolve(response.data);
      })
      .catch((error) => {
        setTimeout(() => reject(error), 3000);
        // reject(error);
      });
  });
}
export function storeItemApi(formData) {
  console.log('storeItemApi');
  console.log(formData);
  const access_token = formData.get('access_token');
  const config = {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .post(ITEM_API_ENDPOINT, formData, config)
      .then((response) => {
        setTimeout(() => resolve(response.data), WAITING_TIME);
        //resolve(response.data);
      })
      .catch((error) => {
        console.error('itemAPI->storeItemApi failed:', error);
        if (error.response) {
          // The request was made, but the server responded with a status code other than 2xx
          // getting sample message
          // ဒီနေရာမှာ error နှစ်မျိုးကို handle လုပ်ဖို့ လိုမယ်။
          let errors = error.response.data.errors;
          let single_message = error.response.data.message;

          if (typeof errors !== 'undefined') {
            console.log('errors is not undefiend');
            // handle parse
            try {
              reject({ message: JSON.stringify(errors) });
            } catch (exp) {}
          }
          if (typeof single_message !== 'undefined') {
            reject(single_message);
          }
          // အပေါ်မှာ stop point နှစ်ခု ရှိတယ်။ အဲ့မှာ မရပ်ခဲ့ရင် ဒီမှာ ရပ်မယ်။
          // ကျွန်တော်မျိုးလဲ မသိပါဆိုတာမျိုး။
          reject('Something went wrong!');
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received. The request may have failed.');
          reject('No response received');
        } else {
          // Something else went wrong
          console.error('An error occurred while making the request:', error.message);
          reject('Request failed: ' + error.message);
        }
      });
  });
}
export function updateItemApi(formData) {
  // getting data
  const access_token = formData.get('access_token');
  const id = formData.get('id');
  // prepare config
  // header
  const config = {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };
  // endpoint
  const ENDPOINT = ITEM_API_ENDPOINT + '/' + id;
  return new Promise((resolve, reject) => {
    axios
      .post(ENDPOINT, formData, config)
      .then((response) => {
        setTimeout(() => resolve(response.data), WAITING_TIME);
        //resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          // getting sample message
          // ဒီနေရာမှာ error နှစ်မျိုးကို handle လုပ်ဖို့ လိုမယ်။
          let errors = error.response.data.errors;
          let single_message = error.response.data.message;

          if (typeof errors !== 'undefined') {
            console.log('errors is not undefiend');
            // handle parse
            try {
              reject({ message: JSON.stringify(errors) });
            } catch (exp) {}
          }
          if (typeof single_message !== 'undefined') {
            reject(single_message);
          }
          // အပေါ်မှာ stop point နှစ်ခု ရှိတယ်။ အဲ့မှာ မရပ်ခဲ့ရင် ဒီမှာ ရပ်မယ်။
          // ကျွန်တော်မျိုးလဲ မသိပါဆိုတာမျိုး။
          reject('Something went wrong!');
        } else if (error.request) {
          // The request was made but no response was received
          reject('No response received');
        } else {
          // Something else went wrong
          reject('Request failed: ' + error.message);
        }
      });
  });
}
export function deleteItemApi(formData) {
  // getting data
  const access_token = formData.get('access_token');
  const id = formData.get('id');
  // preparing config
  const config = {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };
  const ENDPOINT = ITEM_API_ENDPOINT + '/' + id;
  return new Promise((resolve, reject) => {
    axios
      .delete(ENDPOINT, config)
      .then((response) => {
        setTimeout(() => resolve(response.data), WAITING_TIME);
      })
      .catch((error) => {
        if (error.response) {
          // getting sample message

          // getting sample message
          // ဒီနေရာမှာ error နှစ်မျိုးကို handle လုပ်ဖို့ လိုမယ်။
          let errors = error.response.data.errors;
          let single_message = error.response.data.message;

          if (typeof errors !== 'undefined') {
            console.log('errors is not undefiend');
            // handle parse
            try {
              reject({ message: JSON.stringify(errors) });
            } catch (exp) {}
          }
          if (typeof single_message !== 'undefined') {
            reject(single_message);
          }
          // အပေါ်မှာ stop point နှစ်ခု ရှိတယ်။ အဲ့မှာ မရပ်ခဲ့ရင် ဒီမှာ ရပ်မယ်။
          // ကျွန်တော်မျိုးလဲ မသိပါဆိုတာမျိုး။
          reject('Something went wrong!');
        } else if (error.request) {
          // The request was made but no response was received
          reject('No response received');
        } else {
          // Something else went wrong
          reject('Request failed: ' + error.message);
        }
      });
  });
}
