import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { selectItemListApi, storeItemApi, updateItemApi, deleteItemApi } from './itemAPI';

// ဒါက Initial State
const initialState = {
    items: [],
    status: 'idle', // idle, loading, error , succeeded
    error: null,
    errors: {}, // for user register form validation errors

    // status တွေတော့ ခွဲထားပြီး
    // error နဲ့ errors တွေကိုတော့ တစ်ခုတည်းမျှသုံးနေကြတာမျိူး
    // အစဉ်ပြေမလား?

    store_status: 'idle',
    update_status: 'idle',
    delete_status: 'idle',
    selected_item: {
        "id": 23,
        "name": "Item Name",
        "description": "this is item description",
        "background_image_url": "http://localhost/qrmenu/public/storage/8g20pCNm89LgTgpiQafgykCKca8MhNGae2iUr6yL.png",
        "old_price": 10,
        "price": 8,
        "currency": "MMK",
        "current_price": 8,
        "quantity": 1,
        "is_active": 1,
        "menu_id": 9,
        "created_at": "2023-11-26T08:45:42.000000Z",
        "updated_at": "2023-11-26T08:45:42.000000Z",
        "media": []
    }
};

// Thunks
export const selectItemListAsyncThunk = createAsyncThunk(
    'items/select',
    async ({ access_token, page }, { getState }) => {
        const state = getState(); // <-- invoke and access state object
        if (state.items.items.length > 0 && page == 1) {
            return state.items.items;
        }
        const response = await selectItemListApi({ access_token, page });
        return response.data;
    }
);
export const storeItemAsyncThunk = createAsyncThunk(
    'items/store',
    async (formData) => {
        const response = await storeItemApi(formData);
        return response.data;
    }
);
export const updateItemAsyncThunk = createAsyncThunk(
    'items/update',
    async (formData) => {
        const response = await updateItemApi(formData);
        return response.data;
    }
);
export const deleteItemAsyncThunk = createAsyncThunk(
    'items/delete',
    async (formData) => {
        const response = await deleteItemApi(formData);
        return response.data;
    }
);

// Slice
export const itemSlice = createSlice({
    name: 'items',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // two main data setters
        setItems: (state, action) => {
            state.items = action.payload;
        },
        setSelectedItem: (state, action) => {
            state.selected_item = action.payload;
        },


        // detail data updater
        updateSelectedItem: (state, action) => {
            const { name, value } = action.payload;
            state.selected_item[name] = value;
        },

        // status updater
        setItemStatus: (state, action) => {
            state.status = action.payload;
        },
        setItemStoreStatus: (state, action) => {
            state.store_status = action.payload;
        },
        setItemUpdateStatus: (state, action) => {
            state.update_status = action.payload;
        },
        setItemDeleteStatus: (state, action) => {
            state.delete_status = action.payload;
        },

        // set errors, this is for Clear Errors
        setItemErrors: (state, action) => {
            state.errors = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            // select items thunk
            .addCase(selectItemListAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(selectItemListAsyncThunk.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = action.payload;
            })
            .addCase(selectItemListAsyncThunk.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error.message;
            })

            // store item api
            .addCase(storeItemAsyncThunk.pending, (state) => {
                state.store_status = 'loading';
            })
            .addCase(storeItemAsyncThunk.fulfilled, (state, action) => {
                state.store_status = 'succeeded';
                state.items.push(action.payload);
            })
            .addCase(storeItemAsyncThunk.rejected, (state, action) => {
                state.store_status = 'error';
                // နှစ်ခုတည်းက တစ်ခု ယူရမှာ ။
                try {
                    // try multiple errors
                    state.errors = JSON.parse(action.error.message); // Store the error message
                    const firstKey = Object.keys(JSON.parse(action.error.message))[0];
                    const firstItem = JSON.parse(action.error.message)[firstKey][0];

                    state.error = firstItem;
                }
                catch (err) {
                    // error message
                    state.error = action.error.message; // Store the error message
                }
            })

            // update item api
            .addCase(updateItemAsyncThunk.pending, (state) => {
                state.update_status = 'loading';
            })
            .addCase(updateItemAsyncThunk.fulfilled, (state, action) => {
                state.update_status = 'succeeded';
                // find and update local item state
                state.selected_item = action.payload;
            })
            .addCase(updateItemAsyncThunk.rejected, (state, action) => {
                // handle multiple errors for remote validation
                state.update_status = 'error';
                try {
                    // try multiple errors
                    state.errors = JSON.parse(action.error.message); // Store the error message
                }
                catch (err) {
                    // error message
                    state.error = action.error.message; // Store the error message
                }
            })

            // delete item api
            .addCase(deleteItemAsyncThunk.pending, (state) => {
                state.delete_status = 'loading';
            })
            .addCase(deleteItemAsyncThunk.fulfilled, (state, action) => {
                state.delete_status = 'succeeded';
                state.items = state.items.filter(item => item.id !== action.payload.id);
            })
            .addCase(deleteItemAsyncThunk.rejected, (state, action) => {
                // handle multiple errors for remote validation
                state.delete_status = 'error';
                state.error = action.error.message; // Store the error message
                try {
                    state.errors = JSON.parse(action.error.message); // Store the error message
                }
                catch (error) {
                    state.errors = {};
                }
            });
    },
});

// actions
export const {
    setItems,
    setSelectedItem, updateSelectedItem,
    setItemStatus, setItemStoreStatus, setItemUpdateStatus, setItemDeleteStatus,
    setItemErrors
} = itemSlice.actions;

// selectors
/* Global State / Combined Reducers ကနေ select လုပ်နေတယ်ဆိုတာ သတိကပ်ထားရမယ် */
// main data (၂) မျိုး
export const selectItemList = (state) => state.items.items;
export const selectSelectedItem = (state) => state.items.selected_item;
// CRUD Status (၄)​ မျိုး 
export const selectItemStatus = (state) => state.items.status;
export const selectItemStoreStatus = (state) => state.items.store_status;
export const selectItemUpdateStatus = (state) => state.items.update_status;
export const selectItemDeleteStatus = (state) => state.items.delete_status;
// Error (၂)​ မျိုး
export const selectItemError = (state) => state.items.error;
export const selectItemErrors = (state) => state.items.errors;

// export reducer
export default itemSlice.reducer;