import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { selectShopListApi, storeShopApi, updateShopApi, deleteShopApi } from './shopAPI';

// ဒါက Initial State
const initialState = {
  shops: [],
  shops2: [
    {
      "id": 1,
      "selected": true,
      "name": "ရွှေမြန်မာ",
      "background_image_url": "https://qrmenu.sirv.com/Images/qr-menu/1-cover-image.jpg",
      "shop_image_url": "https://qrmenu.sirv.com/Images/qr-menu/2-shop-logo.png",
      "description": "မြန်မာ ဘူဖေး ထမင်းဆိုင်",
      "address": "၇၁လမ်း၊ ၂၈x၂၉လမ်း၊ ပတ်ကုန်းဝန်းကျင်ရပ်ကွက်၊ ချမ်းအေးသာစံမြို့နယ်၊ မန္တ‌လေး။",
      "phone": "094256856695",
      "wifi_name": "WifiParadise",
      "wifi_password": "h0meSw335",
      "url": "shwe-myanmar",
      "media": [
        {
          "id": 4,
          "file_name": "picture4.png",
          "file_url": "picture4.png",
          "file_type": "picture4",
          "file_size": 1,
          "created_at": "2023-11-06T01:48:24.000000Z",
          "updated_at": "2023-11-06T01:48:24.000000Z"
        }
      ],
      "categories": [
        {
          "id": 1,
          "name": "ကြေးအိုး",
          "description": "Category One Description",
          "placement": 1,
          "shop_id": 1,
          "created_at": "2023-11-06T01:48:24.000000Z",
          "updated_at": "2023-11-06T01:48:24.000000Z",
          "media": {
            "id": 4,
            "file_name": "picture4.png",
            "file_url": "picture4.png",
            "file_type": "picture4",
            "file_size": 1,
            "created_at": "2023-11-06T01:48:24.000000Z",
            "updated_at": "2023-11-06T01:48:24.000000Z"
          },
          "menus": [
            {
              "id": 1,
              "name": "Mini ကြေးအိုးများ",
              "description": "Menu One Description",
              "background_image_url": "https://qrmenu.sirv.com/Images/qr-menu/categories/category-one.jpeg",
              "is_active": 1,
              "category_id": 1,
              "created_at": "2023-11-06T01:48:24.000000Z",
              "updated_at": "2023-11-06T01:48:24.000000Z",
              "media": [
                {
                  "id": 10,
                  "file_name": "picture10.png",
                  "file_url": "picture10.png",
                  "file_type": "picture",
                  "file_size": 1,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z"
                }
              ],
              "items": [
                {
                  "id": 1,
                  "name": "ကြေးအိုး ဆီချက်",
                  "description": "ကြက် ၊​ ဝက် ၊​ အမဲ",
                  "background_image_url": null,
                  "old_price": 0,
                  "price": 8500,
                  "currency": "MMK",
                  "current_price": 8500,
                  "quantity": 1,
                  "is_active": 1,
                  "menu_id": 1,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z",
                  "media": [
                    {
                      "id": 15,
                      "file_name": "picture15.png",
                      "file_url": "picture15.png",
                      "file_type": "picture",
                      "file_size": 1,
                      "created_at": "2023-11-06T01:48:24.000000Z",
                      "updated_at": "2023-11-06T01:48:24.000000Z"
                    }
                  ]
                },
                {
                  "id": 2,
                  "name": "မာလာ ကြေးအိုး (ပွဲသေး)",
                  "description": "မာလာ ၊​ မာလာ အစပ်လျော့",
                  "background_image_url": null,
                  "old_price": 0,
                  "price": 14000,
                  "currency": "MMK",
                  "current_price": 14000,
                  "quantity": 1,
                  "is_active": 1,
                  "menu_id": 1,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z",
                  "media": [
                    {
                      "id": 16,
                      "file_name": "picture16.png",
                      "file_url": "picture16.png",
                      "file_type": "picture",
                      "file_size": 1,
                      "created_at": "2023-11-06T01:48:24.000000Z",
                      "updated_at": "2023-11-06T01:48:24.000000Z"
                    }
                  ]
                },
                {
                  "id": 3,
                  "name": "တုန်ယမ်း မာလာ",
                  "description": "အချို ၊​ အစပ်",
                  "background_image_url": null,
                  "old_price": 10,
                  "price": 20000,
                  "currency": "MMK",
                  "current_price": 20000,
                  "quantity": 1,
                  "is_active": 1,
                  "menu_id": 1,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z",
                  "media": [
                    {
                      "id": 17,
                      "file_name": "picture17.png",
                      "file_url": "picture17.png",
                      "file_type": "picture",
                      "file_size": 1,
                      "created_at": "2023-11-06T01:48:24.000000Z",
                      "updated_at": "2023-11-06T01:48:24.000000Z"
                    }
                  ]
                }
              ]
            },
            {
              "id": 2,
              "name": "တုန်ယမ်း ကြေးအိုးများ",
              "description": "Menu Two Description",
              "background_image_url": "https://qrmenu.sirv.com/Images/qr-menu/categories/category-two.jpeg",
              "is_active": 1,
              "category_id": 1,
              "created_at": "2023-11-06T01:48:24.000000Z",
              "updated_at": "2023-11-06T01:48:24.000000Z",
              "media": [
                {
                  "id": 11,
                  "file_name": "picture11.png",
                  "file_url": "picture11.png",
                  "file_type": "picture",
                  "file_size": 1,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z"
                }
              ],
              "items": [
                {
                  "id": 4,
                  "name": "ထမင်းနှင့်ဝက်သားနီချက်",
                  "description": "Item Four Description",
                  "background_image_url": null,
                  "old_price": 0,
                  "price": 30,
                  "currency": "MMK",
                  "current_price": 30,
                  "quantity": 1,
                  "is_active": 1,
                  "menu_id": 2,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z",
                  "media": [
                    {
                      "id": 18,
                      "file_name": "picture18.png",
                      "file_url": "picture18.png",
                      "file_type": "picture",
                      "file_size": 1,
                      "created_at": "2023-11-06T01:48:24.000000Z",
                      "updated_at": "2023-11-06T01:48:24.000000Z"
                    }
                  ]
                },
                {
                  "id": 5,
                  "name": "ထမင်းနှင့် ငါးကြော်နှပ်",
                  "description": "Item Five Description",
                  "background_image_url": null,
                  "old_price": 0,
                  "price": 20,
                  "currency": "MMK",
                  "current_price": 20,
                  "quantity": 1,
                  "is_active": 1,
                  "menu_id": 2,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z",
                  "media": [
                    {
                      "id": 19,
                      "file_name": "picture19.png",
                      "file_url": "picture19.png",
                      "file_type": "picture",
                      "file_size": 1,
                      "created_at": "2023-11-06T01:48:24.000000Z",
                      "updated_at": "2023-11-06T01:48:24.000000Z"
                    }
                  ]
                }
              ]
            },
            {
              "id": 3,
              "name": "ရိုးရိုး ကြေးအိုးများ",
              "description": "Menu Three Description",
              "background_image_url": "https://qrmenu.sirv.com/Images/qr-menu/categories/category-three.jpeg",
              "is_active": 1,
              "category_id": 1,
              "created_at": "2023-11-06T01:48:24.000000Z",
              "updated_at": "2023-11-06T01:48:24.000000Z",
              "media": [
                {
                  "id": 12,
                  "file_name": "picture12.png",
                  "file_url": "picture12.png",
                  "file_type": "picture",
                  "file_size": 1,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z"
                }
              ],
              "items": [
                {
                  "id": 6,
                  "name": "ကြက်အသဲအမြစ်",
                  "description": "Item Six Description",
                  "background_image_url": null,
                  "old_price": 10,
                  "price": 30,
                  "currency": "MMK",
                  "current_price": 30,
                  "quantity": 1,
                  "is_active": 1,
                  "menu_id": 3,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z",
                  "media": [
                    {
                      "id": 20,
                      "file_name": "picture20.png",
                      "file_url": "picture20.png",
                      "file_type": "picture",
                      "file_size": 1,
                      "created_at": "2023-11-06T01:48:24.000000Z",
                      "updated_at": "2023-11-06T01:48:24.000000Z"
                    }
                  ]
                },
                {
                  "id": 7,
                  "name": "ငါးနှပ်ကြော်",
                  "description": "Item Six Description",
                  "background_image_url": null,
                  "old_price": 10,
                  "price": 30,
                  "currency": "MMK",
                  "current_price": 30,
                  "quantity": 1,
                  "is_active": 1,
                  "menu_id": 3,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z",
                  "media": []
                },
                {
                  "id": 8,
                  "name": "ဘဲဉအချဉ်ဟင်း",
                  "description": "Item Six Description",
                  "background_image_url": null,
                  "old_price": 10,
                  "price": 30,
                  "currency": "MMK",
                  "current_price": 30,
                  "quantity": 1,
                  "is_active": 1,
                  "menu_id": 3,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z",
                  "media": []
                }
              ]
            },
            {
              "id": 4,
              "name": "ဆီချက် ကြေးအိုးများ",
              "description": "Menu Four Description",
              "background_image_url": "https://qrmenu.sirv.com/Images/qr-menu/categories/category-four.jpeg",
              "is_active": 1,
              "category_id": 1,
              "created_at": "2023-11-06T01:48:24.000000Z",
              "updated_at": "2023-11-06T01:48:24.000000Z",
              "media": [
                {
                  "id": 13,
                  "file_name": "picture13.png",
                  "file_url": "picture13.png",
                  "file_type": "picture",
                  "file_size": 1,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z"
                }
              ],
              "items": [
                {
                  "id": 9,
                  "name": "ပဲကတီပါကြော်",
                  "description": "Item Six Description",
                  "background_image_url": null,
                  "old_price": 10,
                  "price": 30,
                  "currency": "MMK",
                  "current_price": 30,
                  "quantity": 1,
                  "is_active": 1,
                  "menu_id": 4,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z",
                  "media": []
                },
                {
                  "id": 10,
                  "name": "ဘဲဉကုလားဟင်း",
                  "description": "Item Six Description",
                  "background_image_url": null,
                  "old_price": 10,
                  "price": 30,
                  "currency": "MMK",
                  "current_price": 30,
                  "quantity": 1,
                  "is_active": 1,
                  "menu_id": 4,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z",
                  "media": []
                }
              ]
            }
          ]
        },
        {
          "id": 2,
          "name": "ဆီချက်",
          "description": "Category Two Description",
          "placement": 2,
          "shop_id": 1,
          "created_at": "2023-11-06T01:48:24.000000Z",
          "updated_at": "2023-11-06T01:48:24.000000Z",
          "media": {
            "id": 5,
            "file_name": "picture5.png",
            "file_url": "picture5.png",
            "file_type": "picture",
            "file_size": 1,
            "created_at": "2023-11-06T01:48:24.000000Z",
            "updated_at": "2023-11-06T01:48:24.000000Z"
          },
          "menus": [
            {
              "id": 5,
              "name": "မနက်စာများ",
              "description": "Menu Five Description",
              "background_image_url": "https://qrmenu.sirv.com/Images/qr-menu/categories/category-five.jpeg",
              "is_active": 1,
              "category_id": 2,
              "created_at": "2023-11-06T01:48:24.000000Z",
              "updated_at": "2023-11-06T01:48:24.000000Z",
              "media": [
                {
                  "id": 12,
                  "file_name": "picture12.png",
                  "file_url": "picture12.png",
                  "file_type": "picture",
                  "file_size": 1,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z"
                }
              ],
              "items": [
                {
                  "id": 11,
                  "name": "ထမင်း",
                  "description": "Item Six Description",
                  "background_image_url": null,
                  "old_price": 10,
                  "price": 30,
                  "currency": "MMK",
                  "current_price": 30,
                  "quantity": 1,
                  "is_active": 1,
                  "menu_id": 5,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z",
                  "media": []
                },
                {
                  "id": 12,
                  "name": "ကြက်စတေး",
                  "description": "Item Six Description",
                  "background_image_url": null,
                  "old_price": 10,
                  "price": 30,
                  "currency": "MMK",
                  "current_price": 30,
                  "quantity": 1,
                  "is_active": 1,
                  "menu_id": 5,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z",
                  "media": []
                },
                {
                  "id": 13,
                  "name": "ငါးကွန်ဘို",
                  "description": "Item Six Description",
                  "background_image_url": null,
                  "old_price": 10,
                  "price": 30,
                  "currency": "MMK",
                  "current_price": 30,
                  "quantity": 1,
                  "is_active": 1,
                  "menu_id": 5,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z",
                  "media": []
                }
              ]
            },
            {
              "id": 6,
              "name": "ဒံပေါက်",
              "description": "Menu Six Description",
              "background_image_url": "https://qrmenu.sirv.com/Images/qr-menu/categories/category-six.jpeg",
              "is_active": 1,
              "category_id": 2,
              "created_at": "2023-11-06T01:48:24.000000Z",
              "updated_at": "2023-11-06T01:48:24.000000Z",
              "media": [
                {
                  "id": 13,
                  "file_name": "picture13.png",
                  "file_url": "picture13.png",
                  "file_type": "picture",
                  "file_size": 1,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z"
                }
              ],
              "items": [
                {
                  "id": 14,
                  "name": "မာမားချဉ်စပ်",
                  "description": "Item Six Description",
                  "background_image_url": null,
                  "old_price": 10,
                  "price": 30,
                  "currency": "MMK",
                  "current_price": 30,
                  "quantity": 1,
                  "is_active": 1,
                  "menu_id": 6,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z",
                  "media": []
                },
                {
                  "id": 15,
                  "name": "Item Six",
                  "description": "Item Six Description",
                  "background_image_url": null,
                  "old_price": 10,
                  "price": 30,
                  "currency": "MMK",
                  "current_price": 30,
                  "quantity": 1,
                  "is_active": 1,
                  "menu_id": 6,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z",
                  "media": []
                }
              ]
            },
            {
              "id": 7,
              "name": "ဆီချက်",
              "description": "Menu Seven Description",
              "background_image_url": "https://qrmenu.sirv.com/Images/qr-menu/categories/category-seven.jpeg",
              "is_active": 1,
              "category_id": 2,
              "created_at": "2023-11-06T01:48:24.000000Z",
              "updated_at": "2023-11-06T01:48:24.000000Z",
              "media": [
                {
                  "id": 14,
                  "file_name": "picture14.png",
                  "file_url": "picture14.png",
                  "file_type": "picture4",
                  "file_size": 1,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z"
                }
              ],
              "items": [
                {
                  "id": 16,
                  "name": "ရေခဲမုန့်",
                  "description": "Item Six Description",
                  "background_image_url": null,
                  "old_price": 10,
                  "price": 30,
                  "currency": "MMK",
                  "current_price": 30,
                  "quantity": 1,
                  "is_active": 1,
                  "menu_id": 7,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z",
                  "media": []
                },
                {
                  "id": 17,
                  "name": "မာလာရှမ်းကော",
                  "description": "Item Six Description",
                  "background_image_url": null,
                  "old_price": 10,
                  "price": 30,
                  "currency": "MMK",
                  "current_price": 30,
                  "quantity": 1,
                  "is_active": 1,
                  "menu_id": 7,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z",
                  "media": []
                },
                {
                  "id": 18,
                  "name": "ရေခဲသုပ်",
                  "description": "Item Six Description",
                  "background_image_url": null,
                  "old_price": 10,
                  "price": 30,
                  "currency": "MMK",
                  "current_price": 30,
                  "quantity": 1,
                  "is_active": 1,
                  "menu_id": 7,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z",
                  "media": []
                }
              ]
            }
          ]
        },
        {
          "id": 3,
          "name": "အကင်",
          "description": "Category Three Description",
          "placement": 3,
          "shop_id": 1,
          "created_at": "2023-11-06T01:48:24.000000Z",
          "updated_at": "2023-11-06T01:48:24.000000Z",
          "media": {
            "id": 6,
            "file_name": "picture6.png",
            "file_url": "picture6.png",
            "file_type": "picture",
            "file_size": 1,
            "created_at": "2023-11-06T01:48:24.000000Z",
            "updated_at": "2023-11-06T01:48:24.000000Z"
          },
          "menus": [
            {
              "id": 8,
              "name": "ရှမ်းခေါက်ဆွဲ",
              "description": "Menu Eight Description",
              "background_image_url": "https://qrmenu.sirv.com/Images/qr-menu/categories/category-eight.jpeg",
              "is_active": 1,
              "category_id": 3,
              "created_at": "2023-11-06T01:48:24.000000Z",
              "updated_at": "2023-11-06T01:48:24.000000Z",
              "media": [],
              "items": [
                {
                  "id": 19,
                  "name": "ကြက်ကြော် (အစပ်)",
                  "description": "Item Six Description",
                  "background_image_url": null,
                  "old_price": 10,
                  "price": 30,
                  "currency": "MMK",
                  "current_price": 30,
                  "quantity": 1,
                  "is_active": 1,
                  "menu_id": 8,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z",
                  "media": []
                },
                {
                  "id": 20,
                  "name": "ကြက်ကြော် (အချို)",
                  "description": "Item Six Description",
                  "background_image_url": null,
                  "old_price": 10,
                  "price": 30,
                  "currency": "MMK",
                  "current_price": 30,
                  "quantity": 1,
                  "is_active": 1,
                  "menu_id": 8,
                  "created_at": "2023-11-06T01:48:24.000000Z",
                  "updated_at": "2023-11-06T01:48:24.000000Z",
                  "media": []
                }
              ]
            }
          ]
        },
        {
          "id": 4,
          "name": "ဖျော်ရည်",
          "description": "Category One Description",
          "placement": 4,
          "shop_id": 1,
          "created_at": "2023-11-06T01:48:24.000000Z",
          "updated_at": "2023-11-06T01:48:24.000000Z",
          "media": {
            "id": 7,
            "file_name": "picture7.png",
            "file_url": "picture7.png",
            "file_type": "picture",
            "file_size": 1,
            "created_at": "2023-11-06T01:48:24.000000Z",
            "updated_at": "2023-11-06T01:48:24.000000Z"
          },
          "menus": []
        },
        {
          "id": 5,
          "name": "သက်သက်လွတ်",
          "description": "Category One Description",
          "placement": 5,
          "shop_id": 1,
          "created_at": "2023-11-06T01:48:24.000000Z",
          "updated_at": "2023-11-06T01:48:24.000000Z",
          "media": {
            "id": 7,
            "file_name": "picture7.png",
            "file_url": "picture7.png",
            "file_type": "picture",
            "file_size": 1,
            "created_at": "2023-11-06T01:48:24.000000Z",
            "updated_at": "2023-11-06T01:48:24.000000Z"
          },
          "menus": []
        }
      ]
    }
    /*
    {
      "id": 3,
      
      "name": "Shop Name",
      "background_image_url": "",
      "shop_image_url": "",
      "description": "shop desc",
      "address": "add ress",
      "phone": "095421345",
      "wifi_name": "Wifi Name",
      "wifi_password": "Wifi Password",
      "url": "shop-name-3",
      "media": [
        {
          "id": 6,
          "file_name": "picture6.png",
          "file_url": "picture6.png",
          "file_type": "picture",
          "file_size": 1,
          "created_at": "2023-11-06T01:48:24.000000Z",
          "updated_at": "2023-11-06T01:48:24.000000Z"
        }
      ],
      "categories": [
        {
          "id": 6,
          "name": "Category 6",
          "description": "Menu Nine Description",
          "placement": 1,
          "shop_id": 3,
          "created_at": "2023-11-06T05:42:06.000000Z",
          "updated_at": "2023-11-06T06:00:17.000000Z",
          "media": null,
          "menus": [
            {
              "id": 9,
              "name": "menu one",
              "description": "this is description",
              "background_image_url": "",
              "is_active": 1,
              "category_id": 6,
              "created_at": "2023-11-06T05:42:31.000000Z",
              "updated_at": "2023-11-06T05:42:31.000000Z",
              "media": [],
              "items": [
                {
                  "id": 21,
                  "name": "menu name",
                  "description": "this is desc",
                  "background_image_url": null,
                  "old_price": 10,
                  "price": 8,
                  "currency": "MMK",
                  "current_price": 8,
                  "quantity": 1,
                  "is_active": 1,
                  "menu_id": 9,
                  "created_at": "2023-11-06T05:44:54.000000Z",
                  "updated_at": "2023-11-06T05:44:54.000000Z",
                  "media": []
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "id": 4,
      "name": "Shop Name",
      "background_image_url": "http://localhost/qrmenu/public/storage/XDuihQqO14YcrfpPGUXwi7U8DTOwvuHeu3lWxpPP.png",
      "shop_image_url": "http://localhost/qrmenu/public/storage/mBFGIU49vBRbAWFpW9FZMxy3X8PSlI0s5b86ADhz.jpg",
      "description": "shop desc",
      "address": "add ress",
      "phone": "095421345",
      "wifi_name": "Wifi Name",
      "wifi_password": "Wifi Password",
      "url": "shop-name-၄",
      "media": [],
      "categories": []
    }
    */
  ],
  status: 'idle', // idle, loading, error , succeeded
  error: null,
  errors: {}, // for user register form validation errors

  shop_store_status: 'idle',
  shop_update_status: 'idle',
  shop_delete_status: 'idle',
  selected_shop: {
    "id": 4,
    "selected": true,
    "name": "ရွှေမြန်မာ",
    "background_image_url": "https://qrmenu.sirv.com/Images/qr-menu/1-cover-image.jpg",
    "shop_image_url": "https://qrmenu.sirv.com/Images/qr-menu/2-shop-logo.png",
    "description": "မြန်မာ ဘူဖေး ထမင်းဆိုင်",
    "address": "၇၁လမ်း၊ ၂၈x၂၉လမ်း၊ ပတ်ကုန်းဝန်းကျင်ရပ်ကွက်၊ ချမ်းအေးသာစံမြို့နယ်၊ မန္တ‌လေး။",
    "phone": "094256856695",
    "wifi_name": "WifiParadise",
    "wifi_password": "h0meSw335",
    "url": "shwe-myanmar",
    "media": [
      {
        "id": 4,
        "file_name": "picture4.png",
        "file_url": "picture4.png",
        "file_type": "picture4",
        "file_size": 1,
        "created_at": "2023-11-06T01:48:24.000000Z",
        "updated_at": "2023-11-06T01:48:24.000000Z"
      }
    ],
    "categories": [
      {
        "id": 7,
        "name": "this is Menu Name",
        "description": "this is description",
        "placement": 5,
        "shop_id": 4,
        "created_at": "2023-11-20T03:29:26.000000Z",
        "updated_at": "2023-11-20T13:07:24.000000Z",
        "media": null,
        "menus": []
      },
      {
        "id": 8,
        "name": "this is Menu Name",
        "description": "this is description",
        "placement": 4,
        "shop_id": 4,
        "created_at": "2023-11-20T13:05:24.000000Z",
        "updated_at": "2023-11-20T13:07:24.000000Z",
        "media": null,
        "menus": []
      },
      {
        "id": 9,
        "name": "Teagan Guy",
        "description": "Ut non alias rerum v",
        "placement": 2,
        "shop_id": 4,
        "created_at": "2023-11-20T13:05:51.000000Z",
        "updated_at": "2023-11-20T13:07:24.000000Z",
        "media": null,
        "menus": []
      },
      {
        "id": 10,
        "name": "Holmes Hicks",
        "description": "Fugit omnis ipsa m",
        "placement": 1,
        "shop_id": 4,
        "created_at": "2023-11-20T13:07:10.000000Z",
        "updated_at": "2023-11-20T13:07:24.000000Z",
        "media": null,
        "menus": []
      },
      {
        "id": 1,
        "name": "ကြေးအိုး",
        "description": "Category One Description",
        "placement": 1,
        "shop_id": 1,
        "created_at": "2023-11-06T01:48:24.000000Z",
        "updated_at": "2023-11-06T01:48:24.000000Z",
        "media": {
          "id": 4,
          "file_name": "picture4.png",
          "file_url": "picture4.png",
          "file_type": "picture4",
          "file_size": 1,
          "created_at": "2023-11-06T01:48:24.000000Z",
          "updated_at": "2023-11-06T01:48:24.000000Z"
        },
        "menus": [
          {
            "id": 1,
            "name": "Mini ကြေးအိုးများ",
            "description": "Menu One Description",
            "background_image_url": "https://qrmenu.sirv.com/Images/qr-menu/categories/category-one.jpeg",
            "is_active": 1,
            "category_id": 1,
            "created_at": "2023-11-06T01:48:24.000000Z",
            "updated_at": "2023-11-06T01:48:24.000000Z",
            "media": [
              {
                "id": 10,
                "file_name": "picture10.png",
                "file_url": "picture10.png",
                "file_type": "picture",
                "file_size": 1,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z"
              }
            ],
            "items": [
              {
                "id": 1,
                "name": "ကြေးအိုး ဆီချက်",
                "description": "ကြက် ၊​ ဝက် ၊​ အမဲ",
                "background_image_url": null,
                "old_price": 0,
                "price": 8500,
                "currency": "MMK",
                "current_price": 8500,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 1,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z",
                "media": [
                  {
                    "id": 15,
                    "file_name": "picture15.png",
                    "file_url": "picture15.png",
                    "file_type": "picture",
                    "file_size": 1,
                    "created_at": "2023-11-06T01:48:24.000000Z",
                    "updated_at": "2023-11-06T01:48:24.000000Z"
                  }
                ]
              },
              {
                "id": 2,
                "name": "မာလာ ကြေးအိုး (ပွဲသေး)",
                "description": "မာလာ ၊​ မာလာ အစပ်လျော့",
                "background_image_url": null,
                "old_price": 0,
                "price": 14000,
                "currency": "MMK",
                "current_price": 14000,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 1,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z",
                "media": [
                  {
                    "id": 16,
                    "file_name": "picture16.png",
                    "file_url": "picture16.png",
                    "file_type": "picture",
                    "file_size": 1,
                    "created_at": "2023-11-06T01:48:24.000000Z",
                    "updated_at": "2023-11-06T01:48:24.000000Z"
                  }
                ]
              },
              {
                "id": 3,
                "name": "တုန်ယမ်း မာလာ",
                "description": "အချို ၊​ အစပ်",
                "background_image_url": null,
                "old_price": 10,
                "price": 20000,
                "currency": "MMK",
                "current_price": 20000,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 1,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z",
                "media": [
                  {
                    "id": 17,
                    "file_name": "picture17.png",
                    "file_url": "picture17.png",
                    "file_type": "picture",
                    "file_size": 1,
                    "created_at": "2023-11-06T01:48:24.000000Z",
                    "updated_at": "2023-11-06T01:48:24.000000Z"
                  }
                ]
              }
            ]
          },
          {
            "id": 2,
            "name": "တုန်ယမ်း ကြေးအိုးများ",
            "description": "Menu Two Description",
            "background_image_url": "https://qrmenu.sirv.com/Images/qr-menu/categories/category-two.jpeg",
            "is_active": 1,
            "category_id": 1,
            "created_at": "2023-11-06T01:48:24.000000Z",
            "updated_at": "2023-11-06T01:48:24.000000Z",
            "media": [
              {
                "id": 11,
                "file_name": "picture11.png",
                "file_url": "picture11.png",
                "file_type": "picture",
                "file_size": 1,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z"
              }
            ],
            "items": [
              {
                "id": 4,
                "name": "ထမင်းနှင့်ဝက်သားနီချက်",
                "description": "Item Four Description",
                "background_image_url": null,
                "old_price": 0,
                "price": 30,
                "currency": "MMK",
                "current_price": 30,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 2,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z",
                "media": [
                  {
                    "id": 18,
                    "file_name": "picture18.png",
                    "file_url": "picture18.png",
                    "file_type": "picture",
                    "file_size": 1,
                    "created_at": "2023-11-06T01:48:24.000000Z",
                    "updated_at": "2023-11-06T01:48:24.000000Z"
                  }
                ]
              },
              {
                "id": 5,
                "name": "ထမင်းနှင့် ငါးကြော်နှပ်",
                "description": "Item Five Description",
                "background_image_url": null,
                "old_price": 0,
                "price": 20,
                "currency": "MMK",
                "current_price": 20,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 2,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z",
                "media": [
                  {
                    "id": 19,
                    "file_name": "picture19.png",
                    "file_url": "picture19.png",
                    "file_type": "picture",
                    "file_size": 1,
                    "created_at": "2023-11-06T01:48:24.000000Z",
                    "updated_at": "2023-11-06T01:48:24.000000Z"
                  }
                ]
              }
            ]
          },
          {
            "id": 3,
            "name": "ရိုးရိုး ကြေးအိုးများ",
            "description": "Menu Three Description",
            "background_image_url": "https://qrmenu.sirv.com/Images/qr-menu/categories/category-three.jpeg",
            "is_active": 1,
            "category_id": 1,
            "created_at": "2023-11-06T01:48:24.000000Z",
            "updated_at": "2023-11-06T01:48:24.000000Z",
            "media": [
              {
                "id": 12,
                "file_name": "picture12.png",
                "file_url": "picture12.png",
                "file_type": "picture",
                "file_size": 1,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z"
              }
            ],
            "items": [
              {
                "id": 6,
                "name": "ကြက်အသဲအမြစ်",
                "description": "Item Six Description",
                "background_image_url": null,
                "old_price": 10,
                "price": 30,
                "currency": "MMK",
                "current_price": 30,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 3,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z",
                "media": [
                  {
                    "id": 20,
                    "file_name": "picture20.png",
                    "file_url": "picture20.png",
                    "file_type": "picture",
                    "file_size": 1,
                    "created_at": "2023-11-06T01:48:24.000000Z",
                    "updated_at": "2023-11-06T01:48:24.000000Z"
                  }
                ]
              },
              {
                "id": 7,
                "name": "ငါးနှပ်ကြော်",
                "description": "Item Six Description",
                "background_image_url": null,
                "old_price": 10,
                "price": 30,
                "currency": "MMK",
                "current_price": 30,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 3,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z",
                "media": []
              },
              {
                "id": 8,
                "name": "ဘဲဉအချဉ်ဟင်း",
                "description": "Item Six Description",
                "background_image_url": null,
                "old_price": 10,
                "price": 30,
                "currency": "MMK",
                "current_price": 30,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 3,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z",
                "media": []
              }
            ]
          },
          {
            "id": 4,
            "name": "ဆီချက် ကြေးအိုးများ",
            "description": "Menu Four Description",
            "background_image_url": "https://qrmenu.sirv.com/Images/qr-menu/categories/category-four.jpeg",
            "is_active": 1,
            "category_id": 1,
            "created_at": "2023-11-06T01:48:24.000000Z",
            "updated_at": "2023-11-06T01:48:24.000000Z",
            "media": [
              {
                "id": 13,
                "file_name": "picture13.png",
                "file_url": "picture13.png",
                "file_type": "picture",
                "file_size": 1,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z"
              }
            ],
            "items": [
              {
                "id": 9,
                "name": "ပဲကတီပါကြော်",
                "description": "Item Six Description",
                "background_image_url": null,
                "old_price": 10,
                "price": 30,
                "currency": "MMK",
                "current_price": 30,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 4,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z",
                "media": []
              },
              {
                "id": 10,
                "name": "ဘဲဉကုလားဟင်း",
                "description": "Item Six Description",
                "background_image_url": null,
                "old_price": 10,
                "price": 30,
                "currency": "MMK",
                "current_price": 30,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 4,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z",
                "media": []
              }
            ]
          }
        ]
      },
      {
        "id": 2,
        "name": "ဆီချက်",
        "description": "Category Two Description",
        "placement": 2,
        "shop_id": 1,
        "created_at": "2023-11-06T01:48:24.000000Z",
        "updated_at": "2023-11-06T01:48:24.000000Z",
        "media": {
          "id": 5,
          "file_name": "picture5.png",
          "file_url": "picture5.png",
          "file_type": "picture",
          "file_size": 1,
          "created_at": "2023-11-06T01:48:24.000000Z",
          "updated_at": "2023-11-06T01:48:24.000000Z"
        },
        "menus": [
          {
            "id": 5,
            "name": "မနက်စာများ",
            "description": "Menu Five Description",
            "background_image_url": "https://qrmenu.sirv.com/Images/qr-menu/categories/category-five.jpeg",
            "is_active": 1,
            "category_id": 2,
            "created_at": "2023-11-06T01:48:24.000000Z",
            "updated_at": "2023-11-06T01:48:24.000000Z",
            "media": [
              {
                "id": 12,
                "file_name": "picture12.png",
                "file_url": "picture12.png",
                "file_type": "picture",
                "file_size": 1,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z"
              }
            ],
            "items": [
              {
                "id": 11,
                "name": "ထမင်း",
                "description": "Item Six Description",
                "background_image_url": null,
                "old_price": 10,
                "price": 30,
                "currency": "MMK",
                "current_price": 30,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 5,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z",
                "media": []
              },
              {
                "id": 12,
                "name": "ကြက်စတေး",
                "description": "Item Six Description",
                "background_image_url": null,
                "old_price": 10,
                "price": 30,
                "currency": "MMK",
                "current_price": 30,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 5,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z",
                "media": []
              },
              {
                "id": 13,
                "name": "ငါးကွန်ဘို",
                "description": "Item Six Description",
                "background_image_url": null,
                "old_price": 10,
                "price": 30,
                "currency": "MMK",
                "current_price": 30,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 5,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z",
                "media": []
              }
            ]
          },
          {
            "id": 6,
            "name": "ဒံပေါက်",
            "description": "Menu Six Description",
            "background_image_url": "https://qrmenu.sirv.com/Images/qr-menu/categories/category-six.jpeg",
            "is_active": 1,
            "category_id": 2,
            "created_at": "2023-11-06T01:48:24.000000Z",
            "updated_at": "2023-11-06T01:48:24.000000Z",
            "media": [
              {
                "id": 13,
                "file_name": "picture13.png",
                "file_url": "picture13.png",
                "file_type": "picture",
                "file_size": 1,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z"
              }
            ],
            "items": [
              {
                "id": 14,
                "name": "မာမားချဉ်စပ်",
                "description": "Item Six Description",
                "background_image_url": null,
                "old_price": 10,
                "price": 30,
                "currency": "MMK",
                "current_price": 30,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 6,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z",
                "media": []
              },
              {
                "id": 15,
                "name": "Item Six",
                "description": "Item Six Description",
                "background_image_url": null,
                "old_price": 10,
                "price": 30,
                "currency": "MMK",
                "current_price": 30,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 6,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z",
                "media": []
              }
            ]
          },
          {
            "id": 7,
            "name": "ဆီချက်",
            "description": "Menu Seven Description",
            "background_image_url": "https://qrmenu.sirv.com/Images/qr-menu/categories/category-seven.jpeg",
            "is_active": 1,
            "category_id": 2,
            "created_at": "2023-11-06T01:48:24.000000Z",
            "updated_at": "2023-11-06T01:48:24.000000Z",
            "media": [
              {
                "id": 14,
                "file_name": "picture14.png",
                "file_url": "picture14.png",
                "file_type": "picture4",
                "file_size": 1,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z"
              }
            ],
            "items": [
              {
                "id": 16,
                "name": "ရေခဲမုန့်",
                "description": "Item Six Description",
                "background_image_url": null,
                "old_price": 10,
                "price": 30,
                "currency": "MMK",
                "current_price": 30,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 7,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z",
                "media": []
              },
              {
                "id": 17,
                "name": "မာလာရှမ်းကော",
                "description": "Item Six Description",
                "background_image_url": null,
                "old_price": 10,
                "price": 30,
                "currency": "MMK",
                "current_price": 30,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 7,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z",
                "media": []
              },
              {
                "id": 18,
                "name": "ရေခဲသုပ်",
                "description": "Item Six Description",
                "background_image_url": null,
                "old_price": 10,
                "price": 30,
                "currency": "MMK",
                "current_price": 30,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 7,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z",
                "media": []
              }
            ]
          }
        ]
      },
      {
        "id": 3,
        "name": "အကင်",
        "description": "Category Three Description",
        "placement": 3,
        "shop_id": 1,
        "created_at": "2023-11-06T01:48:24.000000Z",
        "updated_at": "2023-11-06T01:48:24.000000Z",
        "media": {
          "id": 6,
          "file_name": "picture6.png",
          "file_url": "picture6.png",
          "file_type": "picture",
          "file_size": 1,
          "created_at": "2023-11-06T01:48:24.000000Z",
          "updated_at": "2023-11-06T01:48:24.000000Z"
        },
        "menus": [
          {
            "id": 8,
            "name": "ရှမ်းခေါက်ဆွဲ",
            "description": "Menu Eight Description",
            "background_image_url": "https://qrmenu.sirv.com/Images/qr-menu/categories/category-eight.jpeg",
            "is_active": 1,
            "category_id": 3,
            "created_at": "2023-11-06T01:48:24.000000Z",
            "updated_at": "2023-11-06T01:48:24.000000Z",
            "media": [],
            "items": [
              {
                "id": 19,
                "name": "ကြက်ကြော် (အစပ်)",
                "description": "Item Six Description",
                "background_image_url": null,
                "old_price": 10,
                "price": 30,
                "currency": "MMK",
                "current_price": 30,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 8,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z",
                "media": []
              },
              {
                "id": 20,
                "name": "ကြက်ကြော် (အချို)",
                "description": "Item Six Description",
                "background_image_url": null,
                "old_price": 10,
                "price": 30,
                "currency": "MMK",
                "current_price": 30,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 8,
                "created_at": "2023-11-06T01:48:24.000000Z",
                "updated_at": "2023-11-06T01:48:24.000000Z",
                "media": []
              }
            ]
          }
        ]
      },
      {
        "id": 4,
        "name": "ဖျော်ရည်",
        "description": "Category One Description",
        "placement": 4,
        "shop_id": 1,
        "created_at": "2023-11-06T01:48:24.000000Z",
        "updated_at": "2023-11-06T01:48:24.000000Z",
        "media": {
          "id": 7,
          "file_name": "picture7.png",
          "file_url": "picture7.png",
          "file_type": "picture",
          "file_size": 1,
          "created_at": "2023-11-06T01:48:24.000000Z",
          "updated_at": "2023-11-06T01:48:24.000000Z"
        },
        "menus": []
      },
      {
        "id": 5,
        "name": "သက်သက်လွတ်",
        "description": "Category One Description",
        "placement": 5,
        "shop_id": 1,
        "created_at": "2023-11-06T01:48:24.000000Z",
        "updated_at": "2023-11-06T01:48:24.000000Z",
        "media": {
          "id": 7,
          "file_name": "picture7.png",
          "file_url": "picture7.png",
          "file_type": "picture",
          "file_size": 1,
          "created_at": "2023-11-06T01:48:24.000000Z",
          "updated_at": "2023-11-06T01:48:24.000000Z"
        },
        "menus": []
      }
    ]
  }
};
const initialState2 = {};

// Thunks
export const selectShopListAsyncThunk = createAsyncThunk(
  'shops/select',
  async ({ access_token, page }, { getState }) => {
    const state = getState(); // <-- invoke and access state object
    if (state.shops.shops.length > 0 && page === 1) {
      // throw new Error('Whoops! already loaded... ');
      return state.shops.shops;
    }

    const response = await selectShopListApi({ access_token, page });
    // console.log("shops/select");
    // console.log(response.data);
    return response.data;
  }
);
export const storeShopAsyncThunk = createAsyncThunk(
  'shops/store',
  async (formData) => {
    const response = await storeShopApi(formData);
    return response.data;
  }
);
export const updateShopAsyncThunk = createAsyncThunk(
  'shops/update',
  async (formData) => {
    const response = await updateShopApi(formData);
    return response.data;
  }
);
export const deleteShopAsyncThunk = createAsyncThunk(
  'shops/delete',
  async (formData) => {
    const response = await deleteShopApi(formData);
    return response.data;
  }
);

// Slice
export const shopSlice = createSlice({
  name: 'shops',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // shop id ပဲ ပေးစရာလိုတာ။ 
    // ဒါကို ဘယ်က တာဝန်ယူမှာလဲ? shop detail page ကလား? 
    // shop detail page ကို လာချင်တဲ့ page တွေကလား?
    setSelectedShop: (state, action) => {
      for (let i = 0; i < state.shops.length; i++) {
        if (state.shops[i].id === action.payload) {
          state.shops[i].selected = true;
          state.selected_shop = JSON.parse(JSON.stringify(state.shops[i]));
        }
        else {
          state.shops[i].selected = false;
        }
      }
    },
    updateSelectedShopInfo: (state, action) => {
      const { name, value } = action.payload;
      state.selected_shop[name] = value;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setShopStoreStatus: (state, action) => {
      state.shop_store_status = action.payload;
    },
    setShopUpdateStatus: (state, action) => {
      state.shop_update_status = action.payload;
    },
    setShopDeleteStatus: (state, action) => {
      state.shop_delete_status = action.payload;
    },
    insertNewCategory: (state, action) => {
      // category အသစ်တစ်ခု ထည့်လိုက်ပြီ။
      // state ထဲက သက်ဆိုင်ရာ shop အောက်မှာ လိုက်ထည့်ပေးရမယ်။
      // state.shops ထဲမှာ ရှိတယ်။
      let insertedCategory = action.payload;
      console.log("insertedCategory is ");
      console.log(insertedCategory);
      // main repo
      for (let i = 0; i < state.shops.length; i++) {
        if (state.shops[i].id == insertedCategory.shop_id) {
          state.shops[i].categories.push(insertedCategory);
        }
      }
      // selected shop
      if (state.selected_shop.id == insertedCategory.shop_id) {
        state.selected_shop.categories.push(insertedCategory);
      }
    },
    updateCategory: (state, action) => {
      // category တစ်ခု update လုပ်လိုက်ပြီ။
      // state ထဲက သက်ဆိုင်ရာ shop အောက်မှာ လိုက်ထည့်ပေးရမယ်။
      // state.shops ထဲမှာ ရှိတယ်။
      let updatedCategory = action.payload;
      // main repo
      for (let i = 0; i < state.shops.length; i++) {
        if (state.shops[i].id == updatedCategory.shop_id) {
          // state.shops[i].categories.push(insertedCategory);
          // find and replace (merge )
          for (let j = 0; j < state.shops[i].categories.length; j++) {
            if (state.shops[i].categories[j].id == updatedCategory.id) {
              state.shops[i].categories[j] = { ...state.shops[i].categories[j], ...updatedCategory }
            }
          }
        }
      }
      // selected shop
      if (state.selected_shop.id == updatedCategory.shop_id) {
        for (let j = 0; j < state.selected_shop.categories.length; j++) {
          if (state.selected_shop.categories[j].id == updatedCategory.id) {
            state.selected_shop.categories[j] = { ...state.selected_shop.categories[j], ...updatedCategory }
          }
        }
      }
    },
    deleteCategory: (state, action) => {
      // category တစ်ခု delete လုပ်လိုက်ပြီ။
      // state ထဲက သက်ဆိုင်ရာ shop အောက်မှာ လိုက် ဖျတ်ပေးရမယ်။
      // state.shops ထဲမှာ ရှိတယ်။
      let deletedCategory = action.payload;
      // main repo
      for (let i = 0; i < state.shops.length; i++) {
        if (state.shops[i].id == deletedCategory.shop_id) {
          state.shops[i].categories = state.shops[i].categories.filter(category => category.id !== deletedCategory.id);
        }
      }
      // selected shop
      if (state.selected_shop.id == deletedCategory.shop_id) {
        if (state.selected_shop.id == deletedCategory.shop_id) {
          state.selected_shop.categories = state.selected_shop.categories.filter(category => category.id !== deletedCategory.id);
        }
      }
    },
    
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(selectShopListAsyncThunk.pending, (state) => {
  //       state.status = 'loading';
  //     })
  //     .addCase(selectShopListAsyncThunk.fulfilled, (state, action) => {
  //       state.status = 'succeeded';
  //       state.shops = action.payload;
  //     })
  //     .addCase(selectShopListAsyncThunk.rejected, (state, action) => {
  //       state.status = 'error';
  //       state.error = action.error.message;
  //     })

  //     // store shop api
  //     .addCase(storeShopAsyncThunk.pending, (state) => {
  //       state.shop_store_status = 'loading';
  //     })
  //     .addCase(storeShopAsyncThunk.fulfilled, (state, action) => {
  //       state.shop_store_status = 'succeeded';
  //       state.shops.push(action.payload);
  //     })
  //     .addCase(storeShopAsyncThunk.rejected, (state, action) => {
  //       // handle multiple errors for remote validation
  //       state.shop_store_status = 'error';
  //       state.error = action.error.message; // Store the error message
  //       // also try catch since 
  //       try{
  //         state.errors = JSON.parse(action.error.message); // Store the error message
  //       }
  //       catch(exp){
  //         console.log("exp in shopSlice errors parsing");
  //         console.log(exp);
  //       }
  //     })

  //     // update shop api
  //     .addCase(updateShopAsyncThunk.pending, (state) => {
  //       state.shop_update_status = 'loading';
  //     })
  //     .addCase(updateShopAsyncThunk.fulfilled, (state, action) => {
  //       state.shop_update_status = 'succeeded';
  //       // find and update
  //       for (let i = 0; i < state.shops.length; i++) {
  //         if (state.shops[i].id == action.payload.id) {
  //           state.shops[i] = action.payload;
  //         }
  //       }
  //     })
  //     .addCase(updateShopAsyncThunk.rejected, (state, action) => {
  //       // handle multiple errors for remote validation
  //       state.shop_update_status = 'error';
  //       state.error = action.error.message; // Store the error message
  //       state.errors = JSON.parse(action.error.message); // Store the error message
  //     })

  //     // delete shop api
  //     .addCase(deleteShopAsyncThunk.pending, (state) => {
  //       state.shop_delete_status = 'loading';
  //     })
  //     .addCase(deleteShopAsyncThunk.fulfilled, (state, action) => {
  //       state.shop_delete_status = 'succeeded';
  //       // find and delete
  //       // test function ထည့်ပေးပြီး test passed တဲ့ item တွေချည်းပဲ array တစ်ခု ပြင်ပေးတာ။
  //       // alert("action.payload.id");
  //       // alert(action.payload.id);
  //       state.shops = state.shops.filter(shop => shop.id !== action.payload.id);
  //     })
  //     .addCase(deleteShopAsyncThunk.rejected, (state, action) => {
  //       // handle multiple errors for remote validation
  //       state.shop_delete_status = 'error';
  //       state.error = action.error.message; // Store the error message
  //       try {
  //         state.errors = JSON.parse(action.error.message); // Store the error message
  //       }
  //       catch (error) {
  //         state.errors = {};
  //       }


  //     });
  // },
});

// actions
export const { setSelectedShop, setErrors, setStatus,
  setShopStoreStatus, updateSelectedShopInfo, setShopUpdateStatus, setShopDeleteStatus,
  // child operations
  insertNewCategory, updateCategory, deleteCategory
} = shopSlice.actions;

// selectors
/* Global State / Combined Reducers ကနေ select လုပ်နေတယ်ဆိုတာ သတိကပ်ထားရမယ် */
// export const selectShopList = (state) => state.shops.shops;
// export const selectShopListStatus = (state) => state.shops.status;
// export const selectShopStoreStatus = (state) => state.shops.shop_store_status;
// export const selectShopUpdateStatus = (state) => state.shops.shop_update_status;
// export const selectShopDeleteStatus = (state) => state.shops.shop_delete_status;
// export const selectShopError = (state) => state.shops.error;
// export const selectShopErrors = (state) => state.shops.errors;
// export const selectSelectedShop = (state) => state.shops.selected_shop;

// export reducer
export default shopSlice.reducer;